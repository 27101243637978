@import '../../styles/components/styling.scss';

.default-navbar {
    @extend .navbar;
}

.default-navbar-logo {
    height: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
}
