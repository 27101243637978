@import '../../styles/base/variables.scss';

// This annoying use of variables here is to get around the Content Security Policy (CSP) regarding inline styles
.qr-code-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    border-style: solid;
    border-color: black;

    &.preview {
        border-radius: $preview / 30;
        border-width: $preview / 150;
        padding: $preview / 45
    }

    &.full-size-modal {
        border-radius: $full-size-modal / 30;
        border-width: $full-size-modal / 150;
        padding: $full-size-modal / 45
    }

    &.email-and-download {
        border-radius: $email-and-download / 30;
        border-width: $email-and-download / 150;
        padding: $email-and-download / 45
    }
}

.qr-code-verb-text {
    margin: 0px;

    &.preview {
        font-size: $preview / 4.5;
        margin-bottom: $preview / 45;
    }

    &.full-size-modal {
        font-size: $full-size-modal / 4.5;
        margin-bottom: $full-size-modal / 45;
    }

    &.email-and-download {
        font-size: $email-and-download / 4.5;
        margin-bottom: $email-and-download / 45;
    }
}

.powered-by-message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.preview {
        margin-bottom: $preview / 40;
    }

    &.full-size-modal {
        margin-bottom: $full-size-modal / 40;
    }

    &.email-and-download {
        margin-bottom: $email-and-download / 40;
    }
}

.powered-by-text {
    margin: 0px;

    &.preview {
        font-size: $preview / 45;
    }

    &.full-size-modal {
        font-size: $full-size-modal / 45;
    }

    &.email-and-download {
        font-size: $email-and-download / 45;
    }
}

.payment-type-section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;

    &.preview {
        margin-bottom: $preview / -45;
    }

    &.full-size-modal {
        margin-bottom: $full-size-modal / -45;
    }

    &.email-and-download {
        margin-bottom: $email-and-download / -45;
    }
}

.payment-type-icon {
    &.preview {
        font-size: $preview / 4;
    }

    &.full-size-modal {
        font-size: $full-size-modal / 4;
    }

    &.email-and-download {
        font-size: $email-and-download / 4;
    }
}

.qr-code-logo {
    &.preview {
        height: $preview / 17.5;
    }

    &.full-size-modal {
        height: $full-size-modal / 17.5;
    }

    &.email-and-download {
        height: $email-and-download / 17.5;
    }
}
