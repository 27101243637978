@import '../../../styles/base/variables.scss';

.faq-page-wrapper {
    width: 100%;
}

.faq-title {
    font-size: clamp($largeFontSize, 6vw, $xLargeFontSize);
}

.faq {
    width: 100%;
    border-bottom: 1px solid $grey-light;

    &.last-item {
        border-bottom: 1px solid transparent;
    }
}

.faq-question-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    padding-left: 5px;
    padding-right: 5px;
}

.faq-question-bar-text {
    font-size: clamp($mediumFontSize, 1.5vw, $largeFontSize);
    margin: 0px;
}

.faq-question-bar-icon {
    height: 100%;
    color: black;
    margin-left: 20px;
}

.faq-answer {
    background-color: white;
    border-radius: 5px;
    margin-bottom: 10px;

    p {
        font-size: clamp($smallFontSize, 1.5vw, $mediumFontSize);
        margin: 0px;
        padding: 15px;
    }
}
