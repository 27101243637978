@import '../styles/base/variables.scss';
@import '../styles/components/buttons.scss';

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent black background for the blur effect */
    backdrop-filter: blur(2.5px); /* Apply blur effect to the background */
    z-index: 9999;
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;
    max-width: 389px;
    background-color: $off-white-background;
    border-radius: 5px;
    padding: 20px;
}
  
.modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}
  
.modal-close-button {
    @extend .pill-button;

    padding: 7.5px;
    padding-left: 30px;
    padding-right: 30px;
    width: auto;
    font-size: $smallFontSize;
    margin-top: 20px;
    transition: $button-press-transition;

    &:active:hover {
        transform: $button-press-transform;
    }
}

.modal-content-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $largeFontSize;
    margin: 0px;
    width: 100%;
    font-family: $headingFontFamily;
    text-align: center;
}

.modal-content-description {
    color: $grey-mid;
    font-size: $xSmallFontSize;
    margin-bottom: 20px;
    margin-top: 10px;
    text-align: center;
}

.modal-content-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0px;
    width: 100%;
}

.modal-content-action-button-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    height: 40px;
}

.modal-content-action-button {
    @extend .pill-button-orange;

    width: 70%;
    height: 100%;
    font-size: $mediumFontSize;
    
    &.disabled {
        background-color: $grey-light;
        pointer-events: none;
        outline: 1px solid $grey-light;
    }
}
