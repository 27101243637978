@import '../../styles/base/variables.scss';
@import '../../styles/components/buttons.scss';

.landing-page-heading-text {
    font-size: clamp($largeFontSize, 10vw, 60px);
    font-family: $boldHeadingFontFamily;
    color: white;
    text-align: center;
    margin-top: 35px;
    margin-bottom: 0px;
    max-width: $landing-page-display-width * 0.7;

    span {
        color: $orange-primary;
        //display: inline;
    }
}

.landing-page-header-image-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp($mediumFontSize, 4vw, 20px);
    font-family: $lightPrimaryFontFamily;
    color: $grey-light;
    text-align: center;
    margin-top: 35px;
    margin-bottom: 0px;
    max-width: $landing-page-display-width * 0.5;
}

.landing-page-header-image {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    max-width: $landing-page-display-width * 0.5;
    margin-top: 15px;
    margin-bottom: 35px;
}

.landing-page-image-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 35px;
    margin-bottom: 35px;
    max-width: $max-display-width * 1.5;

    h2 {
        color: white;
        margin: 0px;
        font-size: clamp($mediumFontSize, 8vw, 45px);
        text-align: center;
    }

    img {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 25px;
    }

    p {
        color: white;
        text-align: center;
        font-size: clamp($smallFontSize, 3.5vw, 20px);
        margin: 0px;
        max-width: 75%;
    }
}

.landing-page-content-tile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: #1C1C1E;
    border-radius: 10px;
    margin-top: 35px;
    margin-bottom: 35px;
    max-width: $max-display-width * 1.5;
}

.landing-page-content-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    margin: 15px;
    margin-top: 35px;
    margin-bottom: 35px;

    h2 {
        margin: 0px;
        margin-bottom: 20px;
        font-size: clamp($mediumFontSize, 8vw, 45px);
        max-width: $landing-page-display-width / 2;
        text-align: center;
    }

    p {
        margin: 10px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: clamp($smallFontSize, 3.5vw, 20px);
        text-align: center;
        max-width: $landing-page-display-width / 2;
        color: $grey-light
    }
}

.landing-page-button {
    @extend .pill-button-transparent;

    margin-top: 30px;
    width: auto;
    color: white;
    outline: 1.5px solid white;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: clamp($smallFontSize, 3.5vw, 20px);

    &.orange {
        outline: 1.5px solid $orange-primary;
    }
}

.landing-page-bullet-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}


.landing-page-bullet-list-item {
    margin-bottom: 7.5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.left-aligned {
        justify-content: flex-start;
    }

    &.last {
        margin-bottom: 0px;
    }

    p {
        padding: 0px;
        margin: 0px;
        margin-left: 7.5px;
        font-size: clamp($smallFontSize, 3.5vw, 20px);
        text-align: center;
        
        &.left-aligned {
            text-align: left;
    }
    }

    .landing-page-bullet-list-check {
        color: $orange-primary;
        flex-shrink: 0;
    }
}
