@import '../../styles/base/variables';
@import '../../styles/components/buttons.scss';
@import '../../styles/components/styling.scss';

.connect-to-stripe-icon-wrapper {
    width: 100%;
    margin-top: 60px;
}

.connect-to-stripe-icon {
    font-size: 90px;
    color: $stripe-purple-primary;
    overflow: hidden;
    height: 60px;
    width: 75px;
}

.connect-to-stripe-title-wrapper {
    width: 100%;
}

.connect-to-stripe-title {
    font-size: $xLargeFontSize;
    margin: 0px;
}

.connect-to-stripe-text {
    font-size: 14px;
    color: $grey-mid;
    margin-top: 30px;
}
