@import '../../../styles/base/variables.scss';

.about-logo-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: black;
}

.about-logo {
    width: 30%;
    margin-top: 25px;
    margin-bottom: 25px;
}