@import '../../styles/components/styling.scss';
@import '../../styles/components/buttons.scss';

.signed-in-profile-page-navbar {
    @extend .inner-navbar;
}

.signed-in-profile-page-navbar-content {
    @extend .inner-navbar-content;
}

.signed-in-profile-page-navbar-button {
    @extend .inner-navbar-button;
}

.signed-in-profile-page-navbar-icon {
    height: 70%;
    width: auto;
    color: black;
    
    &.selected {
        color: white;
    }
}

.signed-in-profile-page-tab-content {
    @extend .inner-navbar-tab-content;
}
