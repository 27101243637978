@import '../../styles/base/variables.scss';

h1 {
    font-size: clamp($largeFontSize, 3.5vw, $xLargeFontSize);
    margin-top: 75px;
    margin-bottom: 20px
}

h2 {
    font-size: clamp($mediumFontSize, 2.5vw, $largeFontSize);
    margin-top: 20px;
    margin-bottom: 10px;
}

p {
    font-size: clamp($smallFontSize, 2.25vw, $mediumFontSize);
    margin-top: 20px;
}

li {
    font-size: clamp($smallFontSize, 2.25vw, $mediumFontSize);
}
