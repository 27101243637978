@import '../styles/base/variables.scss';

.page-content {
    position: relative;
    width: $page-content-width;
    max-width: $max-display-width;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
    margin-top: $navbar-height;

    &.full-width {
        max-width: $landing-page-display-width;
        width: $page-content-width;
    }

    &.align-left {
        align-items: flex-start;
    }
}
