@import '../../styles/components/styling.scss';
@import '../../styles/components/buttons.scss';
@import '../../styles/base/variables.scss';

.view-edit-profile-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.view-edit-profile-page-message {
    font-size: $xSmallFontSize;
    margin: 5px;
    margin-bottom: 15px;
    color: $grey-mid;
}

.view-edit-profile-navbar {
    width: 100%;
}

.view-edit-profile-navbar-content {
    @extend .inner-navbar-content;
}

.view-edit-profile-navbar-button {
    @extend .pill-button;

    font-size: $smallFontSize;
    border-radius: 0px;
    height: 30px;
    outline: none;
    color: $grey-mid;
    background-color: transparent;
    border-bottom: 1px solid transparent;

    &.selected {
        border-color: black;
        color: black
    }
}

.view-edit-profile-tab-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
}
