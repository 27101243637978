@use '../../styles.scss';

@import '../../styles/components/buttons.scss';
@import '../../styles/components/styling.scss';
@import '../../styles/base/variables.scss';

.payment-navbar {
    @extend .navbar;
}

.payment-central-navbar {
    @extend .central-navbar;

    position: relative;
}

.payment-navbar-logo-wrapper {
    @extend .navbar-logo-wrapper;
}

.navbar-logo {
    height: 100%;
}

.who-are-we-icon-wrapper {
    position: absolute;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $navbar-height;
    width: $navbar-height;
}

.who-are-we-icon {
    @extend .navbar-icon;
}

.who-are-we-modal-text {
    margin: 0px;
    margin-top: 20px;
    font-size: $smallFontSize;
    text-align: center;
}
