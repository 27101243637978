@import '../styles/base/variables.scss';

.text {
    &.black {
        color: black;

        &:hover {
            color: $orange-primary;
        }

        &:active:hover {
            color: $orange-dark;
        }
    }

    &.white {
        color: white;

        &:hover {
            color: $orange-primary;
        }

        &:active:hover {
            color: $orange-dark;
        }
    }

    &.grey-mid {
        color: $grey-mid;

        &:hover {
            color: $orange-primary;
        }

        &:active:hover {
            color: $orange-dark;
        }
    }
}