@use '../../styles.scss';

@import '../../styles/components/buttons.scss';
@import '../../styles/components/styling.scss';
@import '../../styles/base/variables.scss';

.signed-in-navbar {
    @extend .navbar;
}

.iphone-cutout-buffer {
    height: 20px;
    background-color: blue;
    width: 100%;
}

.signed-in-central-navbar {
    @extend .central-navbar;
}

.signed-in-navbar-logo-wrapper {
    @extend .navbar-logo-wrapper;
}

.navbar-logoo {
    height: 100%;
    width: 100%
}

.signed-in-navbar-icon {
    @extend .navbar-icon;

    &.profile-image {
        height: 50%;
        width: auto;
        border-radius: 50%;

        &.selected {
            outline: 1.5px solid white;
        }
    }
}
