@import '../../../styles/components/inputs.scss';
@import '../../../styles/base/variables.scss';

.social-media-input-item {
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
}

.social-media-input-field {
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-media-icon {
    height: 100%;
    margin-right: 7.5px;
}

.social-media-input {
    @extend .text-input;

    &.website {
        &:focus {
            outline: 1px solid $website-colour;
        }
    }

    &.instagram {
        &:focus {
            outline: 1px solid $instagram-colour;
        }
    }

    &.facebook {
        &:focus {
            outline: 1px solid $facebook-colour;
        }
    }

    &.youtube {
        &:focus {
            outline: 1px solid $youtube-colour;
        }
    }

    &.spotify {
        &:focus {
            outline: 1px solid $spotify-colour;
        }
    }
}