@import '../../../styles/components/buttons.scss';
@import '../../../styles/components/styling.scss';
@import '../../../styles/base/variables.scss';

.dashboard-navbar {
    @extend .inner-navbar;
}

.dashboard-navbar-content {
    @extend .inner-navbar-content;
}

.dashboard-navbar-button {
    @extend .inner-navbar-button;
}

.dashboard-tab-content {
    @extend .inner-navbar-tab-content;
}

.no-transactions-message {
    text-align: center;
    color: $grey-mid;
}

.transaction-page-description {
    text-align: center;
    color: $grey-mid;
    font-size: $xSmallFontSize;
}

.transaction-list {
    width: 100%;
}

.transaction-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid $grey-light;
    padding-top: 5px;
    padding-bottom: 5px;

    &.last {
        border-bottom: none;
    }
}

.transaction-status-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;

    .transaction-icon {
        font-size: 25px;
        border-radius: 100%;

        &.paid {
            color: $success-green-light;
            background-color: $success-green-dark;
        }
        
        &.pending {
            color: $grey-light;
            background-color: $grey-mid;
        }

        &.in-transit {
            color: $grey-light;
        }
    }
}

.transaction-details-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.transaction-amount {
    margin: 0px;
    font-size: $smallFontSize;
    color: black;
}

.transaction-date {
    margin: 0px;
    color: $grey-mid;
    font-size: $xSmallFontSize;
}

.transaction-pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.transaction-pagination-button {
    @extend .button;

    display: flex;
    align-items: center;
    justify-content: center;
    color: $grey-mid;
    width: 40px;
    height: 40px;
    margin: 2.5px;

}

.transaction-pagination-button.active {
    color: black; /* Darker shade for active button */
    font-family: $boldPrimaryFontFamily;
}

.edit-payout-schedule-button {
    @extend .button;

    width: auto;
    font-family: $boldPrimaryFontFamily;
    margin-left: 20px;
    padding: 10px;
    color: black;
}

.payout-schedule-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.payout-schedule-text {
    color: $grey-mid;
    font-size: $xSmallFontSize;
}
