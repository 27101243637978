@import '../../../styles/base/variables.scss';

.suggested-values-selection-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: $mediumFontSize;
}

.suggested-value-input {
    background-color: transparent;
    border-style: solid;
    border-width: 0px;
    border-bottom-width: 1px;
    border-radius: 0px;
    border-color: black;
    outline: none;
    text-align: center;
    height: 30px;
    width: 30%;
    font-size: $mediumFontSize;
    font-family: $primaryFontFamily;

    &:focus {
        border-color: $orange-dark;
    }
}

.add-suggested-value-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    border-radius: 100%;
    font-size: 50px;
    margin-left: 10px;
    transition: $button-press-transition;

    &:active:hover {
        transform: $button-press-transform;
    }

    &.disabled {
        &:active:hover {
            transform: none;
        }

        color: $grey-light;
    }
}

.suggested-values-display-section {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    margin-top: 14px;
}

.suggested-value-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    border-width: 1px;
    border-style: dashed;
    border-color: black;
    color: black;
    background-color: transparent;
    width: 30%;
    height: 50px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: $mediumFontSize;
}

.remove-suggested-value-icon {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: white;
    border-radius: 50px;
    color: $error-red-dark;
    font-size: 22.5px;
}
