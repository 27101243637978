@import '../../../styles/components/buttons.scss';
@import '../../../styles/base/variables.scss';

.contact-page-form-section {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.recaptcha-section {
    margin-top: 0px;
}

.contact-page-form-submit-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    height: 50px;
}

.contact-page-submit-form-button {
    @extend .pill-button-orange;

    width: 50%;
    font-size: $mediumFontSize;
    outline: none;
    border: none;
}

.message-sent-successfully-circle-check {
    margin-top: 30px;
    color: $orange-primary;
    font-size: 120px;
    border-radius: 50%;
    background-color: white;
}

.message-sent-successfully-title {
    font-size: $mediumFontSize;
    margin: 5px;
    font-weight: 600;
    color: $orange-primary;
}

.message-sent-successfully-text {
    margin-top: 30px;
    text-align: center;
}

.return-to-homepage-button {
    @extend .pill-button;

    width: auto;
    height: auto;
    margin-top: 50px;
    padding: 10px;
    padding-left: 25px;
    padding-right: 25px;
}
