@import '../styles/base/variables.scss';
@import '../styles/components/buttons.scss';
@import '../styles/components/inputs.scss';
@import '../styles/components/styling.scss';

.navigation-arrow-button-wrapper {
    @extend .button;

    z-index: 100;
    height: auto;
    width: auto;

    &.bottom-left {
        position: absolute;
        bottom: -100px;
        left: 0px;
    }

    &.bottom-right {
        position: absolute;
        bottom: -100px;
        right: 0px;
    }
}

.navigation-arrow-button {
    border-radius: 100%;
    background-color: white;
    font-size: 55px;
    color: $orange-primary;

    &.back {
        color: $grey-light;
    }

    &.disabled {
        color: $grey-light;
        background-color: transparent;
    }
}
