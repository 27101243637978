@import '../base/variables.scss';

.centre-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.navbar {
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: $navbar-height;
    position: fixed;
    top: 0;
    z-index: 1000; /* Ensure it stays above other elements */
}

.central-navbar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    width: 100%;
    max-width: $max-display-width;
    padding-left: 10px;
    padding-right: 10px;
}

.navbar-logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $navbar-height;
    width: $navbar-height;
}

.navbar-button-group-right {
    display: flex;
    gap: $navbar-item-spacing; /* Adjust spacing between buttons */
    position: absolute;
    right: $navbar-item-border-margin;
    height: 30px;
}

.inner-navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: $max-display-width;
    height: $inner-navbar-height;
    position: fixed;
    top: $navbar-height;
    width: $page-content-width*1.025; // To ensure no content shows on the outside of the inner navbar
    z-index: 1000;
    background-color: $off-white-background;
}

.inner-navbar-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 7.5px;
}

.inner-navbar-fade {
    width: 100%;
    height: 10px;
}

.profile-preview-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.profile-preview {
    transform: scale(0.85);
    transform-origin: top;
    width: 100%;
}

.inner-navbar-tab-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $inner-navbar-height;
    width: 100%;
}
