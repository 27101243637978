@import '../../styles/components/buttons.scss';
@import '../../styles/base/variables.scss';

.settings-page-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
}

.settings-section {
    width: 100%;
    margin-bottom: 20px;
}

.settings-section-title {
    color: $grey-mid;
    margin: 0px;
    font-size: $mediumFontSize;
    font-weight: 600;
}

.settings-section-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid $grey-light;
    padding: 10px;
    padding-left: 5px;
    padding-right: 5px;
    

    &.last-item {
        border-bottom: 1px solid transparent;
    }

    &.shows-content {
        &:hover {
            background-color: $grey-light;
        }
    }
}

.settings-section-item-text {
    flex-grow: 1;
}

.settings-section-item-title {
    color: black;
    font-size: $smallFontSize;
    margin: 0px;
}

.settings-section-item-description {
    color: $grey-mid;
    margin: 0px;
    margin-top: 2.5px;
    font-size: $xSmallFontSize;
}

.settings-section-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    color: black;

    &.checkbox {
        transform: scale(1.4);
        accent-color: black;
    }
}

.sign-out-button {
    @extend .pill-button-transparent;

    font-size: $xSmallFontSize;
    height: 25px;
    width: 40%;
}
