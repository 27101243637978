@import '../styles/base/variables.scss';

.page-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: $off-white-background;
    color: black;
    margin-top: 100px;

    &.black {
        background-color: black;
        color: white;
    }
}

.page-central-footer {
    width: 100%;
    width: $page-content-width;
    max-width: $landing-page-display-width;
}

.page-central-footer-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
}

.footer-logo {
    max-width: 25%;
    object-fit: contain;
}

.footer-legal-content {
    h2 {
        margin-top: 0px;
        font-size: clamp($smallFontSize, 2.5vw, $mediumFontSize);
    }

    p, a {
        font-size: clamp($xSmallFontSize, 1.5vw, $smallFontSize);
    }
}
