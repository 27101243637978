@import '../../styles/base/variables.scss';
@import '../../styles/components/buttons.scss';

.payment-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.enter-bank-details-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 5px*2);
    border-radius: 5px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    padding: 10px;
    background-color: white;
}

.enter-bank-details-section-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    font-size: $smallFontSize;
    margin: 0px;
    padding: 0px;

    p {
        margin: 0px;
    }
}

.enter-bank-details-chevron {
    position: absolute;
    right: 0px;
}

.payment-element-submit-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    height: 50px;
}

.payment-element-submit-button {
    @extend .pill-button;

    height: 80%;
    width: 100%;
    //margin-top: 10px;
    //margin-bottom: 10px;
    font-size: $mediumFontSize;
}
