@import '../../styles/base/variables.scss';
@import '../../styles/components/animations.scss';
@import '../../styles/components/styling.scss';
@import '../../styles/components/buttons.scss';

.payment-page-centre-content {
    @extend .centre-content;
}

.margin-top {
    height: 50px;
}

.payment-page-profile-image-wrapper {
    margin-top: 15px;
    width: 45%;
    position: relative;
}

.payment-page-profile-image {
    border-radius: 50%;
    outline: 2px solid black;
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
}

.profile-name-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -10px;
    position: relative;
}

.payment-page-profile-name {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $largeFontSize;
    background-color: white;
    color: black;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 50px;
    outline: 1px solid black;
    z-index: 1;
    font-family: $headingFontFamily;
    text-align: center
}

.edit-profile-name-button {
    @extend .edit-profile-button;

    top: -15px;
    right: -15px;
}

.pay-selection-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 25px;
    width: 100%;
    padding-bottom: 12px;
    //border: 0.5px solid black;
    border-radius: 5px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    background-color: white;
    position: relative;
}

.pay-selection-buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    margin-top: 10px;
}

.pay-selection-button {
    @extend .pill-button-transparent;

    outline: 1px solid $grey-light;
    height: 37.5px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: $mediumFontSize;

    &.selected {
        background-color: $orange-primary;
        outline: 1px solid $orange-primary;
    }
}

.select-an-amount-text {
    font-size: $smallFontSize;
    margin-top: 10px;
    font-weight: 600;
}

.edit-profile-image-button {
    @extend .edit-profile-button;
}

.edit-suggested-pay-values-button {
    @extend .edit-profile-button;

    top: -15px;
    right: -15px;
}

.edit-profile-pencil-icon {
    @extend .edit-profile-pencil-icon;
}

.custom-value-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.custom-value-text-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.enter-custom-amount-text {
    font-size: $xSmallFontSize;
}

.minimum-amount-text {
    font-size: $xSmallFontSize;
    font-style: italic;
}

.custom-value-input-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.custom-value-currency {
    font-size: $smallFontSize;
}

.custom-amount-input-box {
    background-color: transparent;
    border-style: solid;
    border-width: 0px;
    border-bottom-width: 1px;
    border-radius: 0px;
    border-color: black;
    outline: none;
    text-align: center;
    height: 25px;
    width: 50%;
    max-width: 80%;
    font-size: $smallFontSize;
    font-family: $primaryFontFamily;

    &.selected {
        border-color: $orange-primary;
    }
}

.transaction-total-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
}

.total-text {
    font-size: $smallFontSize;
}

.total-transaction-amount {
    font-size: $xLargeFontSize;
    font-family: $headingFontFamily;
}

.cover-fees-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.cover-fees-selection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.cover-fees-radio-button {
    transform: scale(1.15);
    accent-color: black;
}

.cover-fees-text {
    margin-left: 3px;
    font-size: $xSmallFontSize;
}

.fee-breakdown {
    text-decoration: underline;
    font-size: $xSmallFontSize;
    color: $grey-mid;

    &:hover {
        color: $orange-primary;
    }

    &:active:hover {
        color: $orange-dark;
    }
    &:active:hover {
        color: $orange-dark;
    }

    &.disabled {
        pointer-events: none;
    }
}

.fee-modal-text {
    width: 100%;
    text-align: center;
    font-size: $xSmallFontSize;
    margin: 0px;
}

.fee-breakdown-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.fee-section {
    width: 100%;
    margin-bottom: 25px;
}

.fee-section:last-child {
    margin-bottom: 0px;
}

.fee-title {
    font-size: $smallFontSize;
    font-family: $headingFontFamily;
}

.fee-body {
    margin-top: 2.5px;
    font-size: $xSmallFontSize;
}

.payment-page-terms {
    margin: 0px;
    margin-bottom: 20px;

    p {
        width: 100%;
        margin: 0px;
        text-align: center;
        font-size: $xSmallFontSize;
        color: $grey-mid;
    }
}

.payment-section {
    width: 100%;
}

.aggregator-page-profile-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: $smallFontSize;
}

.aggregated-accounts-section {
    width: 100%;
    margin-top: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.edit-aggregated-accounts-button {
    @extend .edit-profile-button;

    top: -15px;
    right: -15px;
}

.aggregated-account-card-button {
    @extend .button;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-bottom: 5px;

    &.disabled {
        pointer-events: none;
    }
}

.aggregated-account-card-profile-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.aggregated-account-profile-image-wrapper {
    height: 60px;
    aspect-ratio: 1 / 1;

    &.edit {
        height: 40px;
    }
}

.aggregated-account-profile-image {
    border-radius: 50%;
    height: 100%;
    outline: 1px solid black;
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
}

.aggregated-account-display-name {
    margin-left: 20px;
    font-size: $mediumFontSize;
    font-family: $headingFontFamily;
}

.aggregated-account-card-arrow {
    color: $orange-primary;
    font-size: $largeFontSize;
}
