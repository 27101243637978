@import '../styles/components/animations.scss';

.loading-icon-wrapper {
    @extend .loading-icon-wrapper;
}

.loading-icon-wrapper-large-margin-top {
    @extend .loading-icon-wrapper;

    margin-top: 100px;
}

.loading-icon-wrapper-no-margin-top {
    @extend .loading-icon-wrapper;

    margin-top: 0px;
}
