// Colours
$orange-primary: #ffa500;
$orange-dark: #ff8c00;
$off-white-background: #f5f5f5;
$error-red-dark: #8b0000;
$error-red-light: #ff000025;
$stripe-purple-primary: #5433FF;

$website-colour: #0000FF;
$instagram-colour: #E1306C;
$facebook-colour: #1877F2;
$youtube-colour: #FF0000;
$spotify-colour: #1DB954;

$grey-light: #d3d3d3;
$grey-mid: #808080;

$success-green-light: #CEF6BB;
$success-green-dark: #05690D;

$landing-page-navbar-grey: #222222;

// Styles
$pill-button-border-radius: 1000px;
$input-box-border-radius: 6px;
$max-display-width: 500px;
$landing-page-display-width: $max-display-width*2;
$page-content-width: 87.5%;
$button-border-width: 2px;
$text-input-border-width: 1px;

// Navbar
$navbar-height: 70px;
$navbar-item-spacing: 15px;
$navbar-item-border-margin: 25px;
$inner-navbar-height: 60px;

// Font sizes
$xSmallFontSize: 12.5px;
$smallFontSize: 15px;
$mediumFontSize: 18px;
$largeFontSize: 30px;
$xLargeFontSize: 40px;

// Font Families
$boldHeadingFontFamily: 'PoppinsExtraBold';
$headingFontFamily: 'PoppinsBold';
$boldPrimaryFontFamily: 'PoppinsBold';
$primaryFontFamily: 'PoppinsLight';
$lightPrimaryFontFamily: 'PoppinsExtraLight';

// Spacing

// Transition
$button-press-transition: 0.3s ease;
$button-press-transform: scale(0.95);

// QR Code sizes
$preview: 150px;
$full-size-modal: 270px;
$email-and-download: 750px;
