@import '../../../styles/components/inputs.scss';

.thankyou-message-input {
    @extend .text-input;

    height: 100px;
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
    font-size: 15px;
    font-style: italic;
}
