@import '../../styles/base/variables.scss';
@import '../../styles/components/buttons.scss';

.qr-code-page-title {
    margin-top: 30px;
    font-size: $largeFontSize;
    margin-bottom: 10px;
    font-family: $headingFontFamily;
}

.qr-code-page-text {
    font-size: $xSmallFontSize;
    text-align: center;
    color: $grey-mid;
    margin: 0px;
    margin-bottom: 20px;
    
}

.qr-code-page-action-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 20px;
    width: 75%;
    height: 95px;
}

.qr-code-page-action-button {
    @extend .pill-button;

    height: auto;
    padding-top: 3px;
    padding-bottom: 4px;
    margin-top: 10px;
    font-size: $smallFontSize;

    &.edit {
        @extend .pill-button-orange;
    }

    &.email {
        @extend .pill-button-transparent;
    }
}
