@import '../../styles/base/variables.scss';
@import '../../styles/components/buttons.scss';
@import '../../styles/components/inputs.scss';
@import '../../styles/components/styling.scss';

// .create-profile-page-centre-content {
//     @extend .centre-content;
// }

.create-profile-title-text {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: $largeFontSize;
}

.form-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 10px;
    padding: 0px;
}

.create-profile-form-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background-color: white;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 6px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
}

.create-profile-form-card-title {
    //font-family: $primaryFontFamily;
    margin-top: 10px;
    margin-bottom: 25px;
    font-size: $largeFontSize;
}

.submit-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
    height: 55px;
}

.create-account-page-arrow-button-wrapper {
    @extend .button;

    width: auto;
}

.create-account-page-arrow-button {
    font-size: 55px;
    color: $orange-primary;

    &.back {
        color: $grey-light
    }
}

.create-account-submit-form-loading-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.create-account-page-terms {
    text-align: center;
    margin-top: 15px;

    p {
        font-size: $xSmallFontSize;
        color: $grey-mid;
        margin: 0px;
    }
}
