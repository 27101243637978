@import '../../styles/base/variables.scss';
@import '../../styles/components/buttons.scss';
@import '../../styles/components/inputs.scss';
@import '../../styles/components/styling.scss';

.choose-acount-type-title-text {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: $xLargeFontSize;
    font-weight: 300;
}

.account-card-button-wrapper {
    position: relative;
}

.account-type-card-button {
    @extend .button;

    display: flex;
    flex-direction: row;
    align-items: center;
    color: black;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    border: none;
    padding: 0px;

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.account-type-card-text-section {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    text-align: left;
    height: 100%;
    padding: 10px;

    h2 {
        margin: 0px;
        font-size: $mediumFontSize;
    }

    p {
        margin: 0px;
        margin-top: 10px;
        font-size: $xSmallFontSize;
        color: $grey-mid;
    }
}

.account-type-card-arrow-section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 10px;
    margin: 0px;
}

.account-type-card-arrow {
    color: $orange-primary;
    font-size: 40px;
    padding: 0px;
    margin: 0px;
}

.coming-soon-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    z-index: 10;

    h3 {
        font-family: $boldPrimaryFontFamily;
        font-size: $mediumFontSize;
        color: white;
        background-color: black;
        padding-left: 9px;
        padding-right: 9px;
        border-radius: 10px;
    }
}
