@import '../../../styles/components/inputs.scss';

.edit-payout-modal-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 10px;
}

.edit-payout-modal-text {
    margin-right: 20px;
}

.edit-payout-modal-select {
    @extend .text-input;

    text-align: center;
    width: 100%;
}
