@import '../../styles/base/variables.scss';
@import '../../styles/components/buttons.scss';

.signed-in-homepage-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.signed-in-homepage-card {
    justify-content: space-between;
}

.signed-in-homepage-card-title-section {
    margin: 0px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.signed-in-homepage-card-title {
    margin: 0px;
    font-family: $boldPrimaryFontFamily;
    color: black;
    font-size: $smallFontSize;
}

.signed-in-homepage-card-currency-selector {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.signed-in-homepage-card-currency-button {
    @extend .pill-button-transparent;

    height: auto;
    width: auto;

    padding: 2.5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: $smallFontSize;

    &.selected {
        background-color: black;
        color: white;
    }
}

.signed-in-homepage-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    p {
        text-align: center;
        font-size: $xSmallFontSize;
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.signed-in-homepage-suggestion-button {
    @extend .pill-button;
    width: 50%;
    font-size: $smallFontSize;
    margin-top: 10px;
    margin-bottom: 5px
}

.balance-card-balance-section {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    width: 100%;
}

.balance-card-balance {
    margin: 0px;
    font-family: $boldHeadingFontFamily;
    font-size: 50px;
}

.custom-tooltip {
    background-color: white;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    margin: 0px;
    border-radius: 5px;
    padding: 10px;

    p {
        margin: 0px;
        font-size: $xSmallFontSize;
    }
}

.no-data-graph-message {
    text-align: center;
    max-width: $max-display-width*(2/3);
    color: $grey-light;
}

.error-sign-out-button {
    @extend .pill-button;

    width: 75%;
    margin-top: 10px;
    font-size: $smallFontSize;
    outline: none;
    border: none;
}

.edit-linked-accounts-button {
    @extend .button;

    width: auto;
}

.linked-account-preview-section {
    margin: 10px;
    width: 95%;
}
