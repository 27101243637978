@import './styles/base/variables.scss';

body, html {
    margin: 0;
    padding: 0;
    
    background-color: black;

    // These stop any text from being highlighted
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    -khtml-user-select: none;

    // Disables horizontal scroll and Rubber Banding effect
    overflow-x: hidden;

    // Sets the caret color for the whole page
    caret-color: $orange-primary;

    // Removes highlighting when tapping a clickable div
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    // Styles highlighted text within an input field
    ::selection { 
        background-color: darkorange;
        color: white;
    }

    input {
        &:-webkit-autofill {
            background-color: transparent !important;
        }
        
        &:-moz-placeholder {
            background-color: transparent !important;
        }
        
        &::-moz-placeholder {
            background-color: transparent !important;
        }
        
        &:-ms-input-placeholder {
            background-color: transparent !important;
        }
    }

    font-family: $primaryFontFamily;

    h1 {
        font-family: $boldHeadingFontFamily;
        // line-height reduces excess padding around the text
        line-height: 1.12;
    }

    h2 {
        font-family: $headingFontFamily;
        // line-height reduces excess padding around the text
        line-height: 1;
    }

    p {
        b {
            font-family: $boldPrimaryFontFamily;
        }
    }

    a {
        color: $orange-primary;

        &:hover {
            color: $orange-primary;
        }

        &:active:hover {
            color: $orange-dark;
        }
    }

    button {
        all: unset;
        font-family: $primaryFontFamily;

        -webkit-appearance: none;
        appearance: none;
    }
}

// This can be added to the html class list when needed to disable vertical scroll
.no-vertical-scroll {
    overflow-y: hidden;
}

@font-face {
    font-family: 'PoppinsExtraBold';
    font-weight: 800;
    src: url('/assets/fonts/Poppins-ExtraBold.woff2') format('woff2');
}

@font-face {
    font-family: 'PoppinsBold';
    font-weight: 700;
    src: url('/assets/fonts/Poppins-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'PoppinsLight';
    font-weight: 300;
    src: url('/assets/fonts/Poppins-Light.woff2') format('woff2');
}

@font-face {
    font-family: 'PoppinsExtraLight';
    font-weight: 200;
    src: url('/assets/fonts/Poppins-ExtraLight.woff2') format('woff2');
}
