@import '../../styles/base/variables.scss';

.no-profile-404-icon {
    font-size: 60px;
    margin-top: 30px;
    color: $orange-primary;
}

.no-profile-heart-icon {
    font-size: 250px;
    color: $orange-primary;
}

.no-profile-title {
    font-size: $largeFontSize;
    margin-top: 30px;
}

.no-profile-text {
    margin-top: 0px;
    text-align: center;
    font-size: $xSmallFontSize;
}