@import '../styles/base/variables.scss';
@import '../styles/components/styling.scss';
@import '../styles/components/buttons.scss';

.no-scroll {
    overflow: hidden;
}

.page-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9999;
    background-color: $off-white-background;
    overflow-y: auto; /* Allow scrolling of the entire overlay */
}

.page-overlay-navbar {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: $navbar-height;
    background-color: black;
    z-index: 1; // Puts the navbar infront of the content
}

.page-overlay-central-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: $landing-page-display-width;
    height: $navbar-height;
}

.page-overlay-navbar-logo {
    height: $navbar-height/3;
    margin-left: $navbar-height/3;
}

.page-overlay-close-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $navbar-height;
    width: $navbar-height;
}

.page-overlay-close-icon {
    @extend .navbar-icon;
}

.overlay-bottom-margin {
    margin-top: 75px;
}
