@import '../../../styles/base/variables.scss';

.qr-code-verb-options {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 95%;
}

.qr-code-verb-radio-button {
    transform: scale(1.15);
    accent-color: $orange-dark;
    margin-right: 10px;
}
