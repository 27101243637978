@import '../../styles/base/variables.scss';
@import '../../styles/components/animations.scss';
@import '../../styles/components/inputs.scss';
@import '../../styles/components/buttons.scss';
@import '../../styles/components/styling.scss';

.email-icon-wrapper {
    margin-top: 60px;
    width: 100%;
}

.email-icon {
    font-size: 30px;
}

.whats-your-email-title {
    font-size: $xLargeFontSize;
    margin: 0px;
    width: 100%;
}

form {
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
}

.email-address-input {
    @extend .text-input;
    
    height: 50px;
}

.verification-code-message {
    font-size: $xSmallFontSize;
    margin: 0px;
    margin-top: 5px;
    color: $grey-mid;
}
