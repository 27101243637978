@import '../../styles/base/variables.scss';
@import '../../styles/components/animations.scss';
@import '../../styles/components/buttons.scss';
@import '../../styles/components/styling.scss';

.lock-icon-wrapper {
    width: 100%;
    margin-top: 60px;
}

.lock-icon {
    font-size: 30px;
}

.verify-email-title-wrapper {
    width: 100%;
}

.verify-email-title {
    font-size: $xLargeFontSize;
    margin: 0px;
}

.enter-code-message {
    width: 90%;
    text-align: center;
    font-size: $xSmallFontSize;
    color: $grey-mid;
    margin-top: 5px;
}

.enter-code-email-address {
    width: 90%;
    text-align: center;
    font-size: $smallFontSize;
    color: $grey-mid;
    margin-top: 5px;
}

.code-input-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 55px;
    border-color: black;
    margin-top: 20px;
}

.code-input-box {
    max-width: 15.5%;
    width: 45px;
    height: 100%;
    text-align: center;
    background-color: white;
    border-radius: 6px;
    box-sizing: border-box;
    outline: 1px solid $grey-light;
    border-style: none;
    border-width: $text-input-border-width;
    font-size: $mediumFontSize;
    font-family: $primaryFontFamily;

    &.highlight {
        outline: 1px solid $orange-dark;
    }

    &.input-disabled {
        pointer-events: none;
    }

    &.submit-disabled {
        outline: 1px solid $error-red-dark;
    }
}

.not-received-email-message {
    margin-top: 10px;
    text-decoration: underline;
    font-size: $xSmallFontSize;

    &:active {
        color: $orange-dark;
    }
}

.bulleted-list {
    width: 95%;
    font-size: $smallFontSize;
    padding-left: 10px;
    margin: 0px;
}

li {
    margin-bottom: 6px;
}

.no-code-modal-back-arrow {
    font-size: 14px;
    color: $grey-light;
}
