@import '../../styles/components/styling.scss';
@import '../../styles/components/buttons.scss';

.thankyou-page-centre-content {
    @extend .centre-content;
}

.thankyou-page-circle-check {
    margin-top: 15px;
    color: $orange-primary;
    font-size: 120px;
    border-radius: 50%;
    background-color: white;
}

.payment-successful-text {
    font-size: $mediumFontSize;
    margin: 5px;
    font-weight: 600;
    color: $orange-primary;
}

.a-message-from {
    font-size: $xSmallFontSize;
    margin-top: 35px;
}

.message-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 85%;
    margin-top: 17.5px;
}

.thankyou-page-profile-image {
    width: 20%;
    border-radius: 50%;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    position: relative;
    z-index: 1;
}

.message-box {
    margin-top: -10px;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    background-color: white;
    width: 100%;
    position: relative;
}

.edit-thankyou-message-button {
    @extend .edit-profile-button;

    top: -15px;
    right: -15px;
}

.message-text {
    margin: 15px;
    text-align: center;
    font-size: $smallFontSize;
    font-style: italic;
    word-wrap: break-word;
}

.social-media-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.find-on-text {
    font-size: $xSmallFontSize;
}

.edit-social-media-links-button {
    @extend .edit-profile-button;

    top: -15px;
    right: -15px;
}

.edit-profile-pencil-icon {
    @extend .edit-profile-pencil-icon;
}

.social-media-icon-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 15px;
    gap: 10px;
    position: relative;
}

.thankyou-page-social-media-icon {
    font-size: 50px;
    border-radius: 0 !important;
    color: black;

    &.disabled {
        pointer-events: none;
    }
}

.add-profile-content-button {
    @extend .pill-button;

    margin-top: 30px;
    width: 100%;
    padding: 10px;
    font-size: $mediumFontSize;
}
