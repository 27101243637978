@import '../styles/base/variables.scss';
@import '../styles/components/inputs.scss';

.form-item {
    display: flex;
    align-items: left;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    box-sizing: border-box;
}

.form-item-title {
    font-size: $mediumFontSize;
    margin: 0px;
    margin-bottom: 5px;
    font-weight: 300;

    &.error {
        color: $error-red-dark
    }
}

.form-item-text-input {
    @extend .text-input;

    outline: 1px solid $grey-light;
    font-size: $smallFontSize;
    color: black;

    &.error {
        outline: 1px solid $error-red-dark;
    }

    &.text-area {
        height: 150px;
        min-height: 150px;
        max-height: 500px;
        width: 100%;
        resize: vertical;
        padding: 15px;
        box-sizing: border-box;
    }

    &.thankyou-message-input {
        height: 100px;
        padding: 15px;
        width: 100%;
        box-sizing: border-box;
        min-height: 100px;
    }

    &.website {
        &:focus {
            outline: 1px solid $website-colour;
        }
    }

    &.instagram {
        &:focus {
            outline: 1px solid $instagram-colour;
        }
    }

    &.facebook {
        &:focus {
            outline: 1px solid $facebook-colour;
        }
    }

    &.youtube {
        &:focus {
            outline: 1px solid $youtube-colour;
        }
    }

    &.spotify {
        &:focus {
            outline: 1px solid $spotify-colour;
        }
    }
}

.form-item-label {
    font-size: $xSmallFontSize;
    color: $grey-mid;
    margin: 0px;
    margin-top: 5px;

    &.error {
        color: $error-red-dark;
    }
}

/* Custom styles for the PhoneInput component */
.react-tel-input {
    width: 100%;
    font-size: $smallFontSize; // Match font size of other inputs
    font-family: $primaryFontFamily;
  
    .form-control {
        @extend .text-input;

        outline: 1px solid $grey-light;
        font-size: $smallFontSize;
        padding-left: 65px;
        color: black;

        &.error {
            border: 1px solid $error-red-dark; // Match error border color
        }
        
        &:focus {
            outline: 1px solid $orange-primary;
            box-shadow: none;
        }
    }
  
    .flag-dropdown {

        .selected-flag {
            &::before {
                box-shadow: none;
                border: none;
            }
        }
        
        .country-list {
            outline: 1px solid $grey-light; // Match border style
            border-radius: 6px;
            box-shadow: none;
            overflow-y: auto; // Enable scrolling if needed
            
            .country {
                &:hover {
                    background-color: rgba($orange-primary, 0.5); // Match hover background color
                }
            }
        }
    }
}
