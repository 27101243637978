@import '../../../styles/components/buttons.scss';

.image-upload-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.image-inner-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
}

.profile-image-upload-button {
    @extend .pill-button;
    
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 17px;
    padding-left: 40px;
    padding-right: 40px;
    background-color: transparent;
    color: black;
    outline: 1px solid black;
    width:auto;
}

.profile-image-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    border-radius: 100%;
    border-style: solid;
    border-color: black;
    border-width: 2px;
    overflow: hidden;
    margin-bottom: 10px;
}

.profile-image {
    height: 100%;
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
}
