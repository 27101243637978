@import '../base/variables.scss';

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    transition: $button-press-transition;

    &:not(:disabled):active:hover {
        transform: $button-press-transform;
    }
}

.pill-button {
    @extend .button;
    
    border-radius: $pill-button-border-radius;
    color: white;
    background-color: black;
    outline: 1px solid black;
}

.pill-button-orange {
    @extend .pill-button;

    color: white;
    background-color: $orange-primary;
    outline: 1px solid $orange-primary;
}

.pill-button-transparent {
    @extend .pill-button;

    color: black;
    background-color: transparent;
    outline: 1px solid black;
}

.next-arrow-button {
    position: absolute;
    bottom: 100px;
    right: 0px;
    z-index: 100; /* Ensure the button is on top of other elements */
    color: $orange-primary;
    border-radius: 100%;
    font-size: 55px;
    transition: $button-press-transition;
    background-color: white;

    &:active {
        transform: $button-press-transform;
    }

    &.disabled {
        color: $grey-light;
        background-color: transparent;
        pointer-events: none;

        &:active {
            transform: none;
        }
    }
}

.back-arrow-button {
    @extend .next-arrow-button;

    left: 0px;
    color: $grey-light;
}

.navbar-button {
    @extend .pill-button;

    outline: 1px solid white;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 5px;
    margin-right: 5px;
    color: white;
    font-size: $xSmallFontSize;
    white-space: nowrap;
    height: 40%;
}

.navbar-icon {
    @extend .pill-button;

    border-radius: 0px;
    height: 50%;
    background-color: transparent;
    width: auto;
    border-style: none;
}

.inner-navbar-button {
    @extend .pill-button-transparent;

    font-size: $xSmallFontSize;
    height: 30px;

    &.selected {
        @extend .pill-button-orange;
        color: black;
        height: 30px;
    }
}

.edit-profile-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 30px;
    width: 30px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-color: white;
    z-index: 10;
    transition: $button-press-transition;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);

    &:active:hover {
        transform: $button-press-transform
    }
}

.edit-profile-pencil-icon {
    color: black;
}
