@import '../styles/base/variables.scss';

.error-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    border-radius: 5px;
    background-color: $error-red-light;
}

.error-text {
    margin: 15px;
    font-size: $xSmallFontSize;
    color: $error-red-dark;
    text-align: center;
}
