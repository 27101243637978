@import '../styles/base/variables.scss';

.page-default {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    height: auto;
    width: 100%;
    background-color: $off-white-background;

    &.black {
        background-color: black;
    }

    &.off-white {
        background-color: $off-white-background;
    }
}
