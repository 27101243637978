@import '../../styles/components/buttons.scss';
@import '../../styles/components/styling.scss';

.not-signed-in-navbar {
    @extend .navbar;

    &.landing-page {
        background-color: $landing-page-navbar-grey;
    }
}

.not-signed-in-central-navbar {
    @extend .central-navbar;

    justify-content: space-between;
    padding: 0px;
    max-width: $landing-page-display-width;
}

.not-signed-in-navbar-menu-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.not-signed-in-navbar-logo {
    height: $navbar-height/3;
}

.not-signed-in-navbar-menu-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: $navbar-height;
    position: relative;
    border-radius: 0px;
    border-width: 0px;
}

.not-signed-in-navbar-menu-icon {
    @extend .navbar-icon;
    outline: none;
}

.not-signed-in-navbar-logo {
    height: 30%;
}

.not-signed-in-navbar-sign-in {
    @extend .pill-button;

    outline: 1px solid $orange-primary;
    padding-left: 10px;
    padding-right: 10px;
    font-size: $xSmallFontSize;
    height: 40%;
    width: auto;
    margin-right: $navbar-height/4;
    background-color: transparent;
}

.navbar-dropdown {
    position: absolute;
    top: 100%; // Positions the dropdown directly below the icon
    left: 0; // Aligns the dropdown with the left edge of the icon wrapper
    background-color: white;
    border: 1px solid $grey-light;
    border-radius: 5px;
    width: 200px; // Adjust the width as needed
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    margin-top: 5px;
    margin-left: 5px;
}

.navbar-dropdown-item {
    padding: 10px 20px;
    transition: background-color 0.3s ease;
    font-size: $smallFontSize;
    margin: 0px;

    &:hover {
        background-color: $grey-light;
    }
}
