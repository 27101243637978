@import '../base/variables.scss';

.text-input {
    background-color: white;
    border-radius: $input-box-border-radius;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    font-size: $smallFontSize;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    outline: none;
    border-style: none !important;
    font-family: $primaryFontFamily;

    &:focus {
        outline: $text-input-border-width solid $orange-dark !important;
    }

    &::placeholder {
        font-style: italic;
        color: $grey-light;
        font-size: $smallFontSize;
        font-style: italic;
    }

    &.invalid-field {
        outline: $text-input-border-width solid $error-red-dark;
    }
}
