@import '../../../styles/base/variables.scss';

.aggregated-accounts-input-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: $mediumFontSize;
    margin-bottom: 15px;
}

.add-aggregated-account-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    border-radius: 100%;
    font-size: 50px;
    margin-left: 10px;
    transition: $button-press-transition;

    &:active:hover {
        transform: $button-press-transform;
    }

    &.disabled {
        &:active:hover {
            transform: none;
        }

        color: $grey-light;
    }
}

.linked-accounts-list-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.linked-account-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    color: black;
    font-size: $smallFontSize;
    margin-bottom: 0px;

    &.last {
        margin-bottom: 0px;
    }
}

.remove-linked-account-icon {
    margin-left: 10px;
    background-color: white;
    border-radius: 50px;
    color: $error-red-dark;
    font-size: 22.5px;
}
